import { DOMElement } from 'react';
import './confetti.sass'

import sound01 from './sounds/click-01.mp3';
import sound02 from './sounds/click-02.mp3';
import sound03 from './sounds/click-03.mp3';
import sound04 from './sounds/click-04.mp3';
import sound05 from './sounds/click-05.mp3';

const duration = 800; // ms
const length = 60; // px
const colors = ['violet', 'indigo', 'blue', 'green', 'yellow', 'orange', 'red'];
const audios = [
    new Audio(sound01),
    new Audio(sound02),
    new Audio(sound03),
    new Audio(sound04),
    new Audio(sound05),
];

const centerToEdge = (width: number, height: number, angle: number): {left: number, top: number} => {
    const twoPI = Math.PI*2;
    let theta = angle * Math.PI / 180;
    while (theta < -Math.PI) {
        theta += twoPI;
    }
    while (theta > Math.PI) {
        theta -= twoPI;
    }

    const rectAtan = Math.atan2(height, width);
    const tanTheta = Math.tan(theta);
    let region: number;

    if ((theta > -rectAtan) && (theta <= rectAtan)) {
        region = 1;
    } else if ((theta > rectAtan) && (theta <= (Math.PI - rectAtan))) {
        region = 2;
    } else if ((theta > (Math.PI - rectAtan)) || (theta <= -(Math.PI - rectAtan))) {
        region = 3;
    } else {
        region = 4;
    }

    const result = {left: width/2, top: height/2};
    let xFactor = 1;
    let yFactor = 1;

    switch (region) {
        case 1: yFactor = -1; break;
        case 2: yFactor = -1; break;
        case 3: xFactor = -1; break;
        case 4: xFactor = -1; break;
    }

    if ((region === 1) || (region === 3)) {
        result.left += xFactor * (width / 2.);                                     // "Z0"
        result.top += yFactor * (width / 2.) * tanTheta;
    } else {
        result.left += xFactor * (height / (2. * tanTheta));                        // "Z1"
        result.top += yFactor * (height /  2.);
    }

    return result;
}

const randomId = function(length: number) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
 charactersLength)));
   }
   return result.join('');
}

export const createConfetti = (x: number, y: number, target: Element|null) => {
    if (!target) {
        return;
    }

    let parent: Element = target;
    let found: Element|null = null;
    while (parent && parent.nodeName !== 'HTML' && parent.nodeName !== 'BODY') {
        if (parent.classList.contains('card-clickable') || parent.hasAttribute('href')) {
            found = parent;

            break;
        }

        if (!parent.parentNode) {
            break;
        }

        parent = parent.parentNode as Element;
    }

    if (found === null) {
        return;
    }

    const rect = found.getBoundingClientRect();

    let container = document.createElement('div');
    container.setAttribute('class', 'confetti-container');
    container.setAttribute('style', `left: ${rect.left}px; top: ${rect.top}px; --duration: ${duration}ms; --length: ${length}px`);
    document.body.appendChild(container);

    for (let i = 0; i < 20; i++) {
        const confettiEnvelope = document.createElement('div');
        confettiEnvelope.setAttribute('class', 'confetti-envelope');
        const confetti = document.createElement('div');
        const angle = Math.round(Math.random() * 360);
        confetti.setAttribute('class', `confetti`);
        const randomPosition = centerToEdge(rect.width, rect.height, angle);

        const left = Math.round(randomPosition.left);
        const top = Math.round(randomPosition.top);
        confettiEnvelope.setAttribute('style', `left: ${left}px; top: ${top}px; --angle: ${angle}deg; --color: ${colors[Math.floor(Math.random() * colors.length)]}`);

        confettiEnvelope.appendChild(confetti);
        container.appendChild(confettiEnvelope);
    }

    audios[Math.floor(Math.random() * audios.length)].play();

    setTimeout(() => container.remove(), duration - 1);
}
