exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-skills-architecture-tsx": () => import("./../../../src/pages/skills/architecture.tsx" /* webpackChunkName: "component---src-pages-skills-architecture-tsx" */),
  "component---src-pages-skills-audit-tsx": () => import("./../../../src/pages/skills/audit.tsx" /* webpackChunkName: "component---src-pages-skills-audit-tsx" */),
  "component---src-pages-skills-coaching-tsx": () => import("./../../../src/pages/skills/coaching.tsx" /* webpackChunkName: "component---src-pages-skills-coaching-tsx" */),
  "component---src-pages-skills-development-tsx": () => import("./../../../src/pages/skills/development.tsx" /* webpackChunkName: "component---src-pages-skills-development-tsx" */),
  "component---src-pages-skills-trainings-tsx": () => import("./../../../src/pages/skills/trainings.tsx" /* webpackChunkName: "component---src-pages-skills-trainings-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-talk-tsx": () => import("./../../../src/templates/talk.tsx" /* webpackChunkName: "component---src-templates-talk-tsx" */)
}

